header {
    /* background-image: linear-gradient( rgba(255,255,255,1), rgba(255,255,255,0)); */
}
div {
    /* background-color: #ffffff; */
    
}
.home-background {
    background-image: url('IMG_7446.jpg');
    background-size: cover;
    background-position: center; /* Center the image */
}
h2 {
    padding-top:3rem;
}
@media (max-width: 1000px) {
    h2 {
        padding-top:5rem;
    }
}
.gallery-container {
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 2rem 0;
}

.gallery-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.gallery-container ul li img {
    display: block;
    height: auto;
    max-width: 100%;
}


.gallery-container ul.masonry-gallery {
    columns: 12rem;
    column-gap: 1rem;
}
@media (max-width: 1000px) {
    .gallery-container ul.masonry-gallery {
        columns: 8rem;
        /* columns: 100px 3; */
        column-gap: .25rem;
    }
}

.gallery-container ul.masonry-gallery li {
    break-inside: avoid;
    margin: 0 0 1rem;
}
@media (max-width: 1000px) {
    .gallery-container ul.masonry-gallery li {
        break-inside: avoid;
        margin: 0 0 .25rem;
    }
}

.gallery-container ul.masonry-gallery li:hover {
    transform: scale(1.025);
    opacity: 0.8;
    transition: .75s;
}
